<template>
  <v-app id="app">
    <v-snackbar
      v-model="$store.getters.msg.show"
      :color="$store.getters.msg.color"
      multi-line="multi-line"
      v-bind:timeout="$store.getters.msg.timeout"
      top="top"
    >
      {{ $store.getters.msg.message }}
      <v-btn dark text @click="$store.commit('msgOculta')">
        Close
      </v-btn>
    </v-snackbar>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="$store.getters.token"
      color="#1B6BAA"
    >
      <v-list dense>
        <v-list-item
          link
          v-for="(item, index) in menu"
          :key="index"
          :to="item.action"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#1B6BAA" dark v-if="$store.getters.token">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $store.getters.title }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon large>
        <v-avatar size="42px" item>
          <v-img src="@/assets/images/icono.png" alt="PRIMA"
        /></v-avatar>
      </v-btn>
    </v-app-bar>

    <v-content :class="{ azul: !$store.getters.token }">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-content>
    <v-footer color="#1B6BAA" app v-if="$store.getters.token">
      <div class="white--text text-right" style="width: 100%;">
        &copy; 2016-<span v-text="year"></span> Prima Waste Removal
      </div>
    </v-footer>
  </v-app>
</template>
<style scoped>
.azul {
  background: #1b6baa;
}
</style>
<script>
export default {
  name: "App",
  props: {
    source: String
  },
  data: () => ({
    drawer: null,
    year: new Date().getFullYear(),
    menu: [
      { icon: "mdi-home", title: "Home", action: "/" },
      {
        icon: "mdi-hospital-building",
        title: "Pick Up Locations",
        action: "/locations"
      },
      { icon: "mdi-calendar", title: "Pick Up Calendar", action: "/calendar" },
      { icon: "mdi-file-pdf-box", title: "Manifests", action: "/manifests" },
      { icon: "mdi-file-document", title: "Invoices", action: "/invoices" },
      { icon: "mdi-cart", title: "Pay Bill", action: "/paybill" },
      {
        icon: "mdi-email",
        title: "Special Request",
        action: "/specialrequest"
      },
      {
        icon: "mdi-video",
        title: "BBP Online Training",
        action: "/onlinetraining"
      },
      {
        icon: "mdi-video",
        title: "DOT-RMW Online Training",
        action: "/dotonlinetraining"
      },
      { icon: "mdi-account", title: "My Profile", action: "/myprofile" },
      { icon: "mdi-logout", title: "Logout", action: "/logout" }
    ]
  }),
  components: {},
  mounted: function() {
    var token = this.$ls.get("token", false);
    var customer_id = this.$ls.get("customer_id", 0);
    if (token && customer_id) {
      var datos = {
        token: token,
        customer_id: customer_id
      };
      this.$store.commit("login", datos);
    }
  }
};
</script>
